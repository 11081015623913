import { instance } from '../axios';
import {
  GetApplicantByApplicantIdResponse,
  UserApplicationDto,
} from './application.dto';

export const getUserApplication = async (onlineUserId: string) => {
  if (!onlineUserId) return;
  try {
    const response = await instance.get<UserApplicationDto>(
      `applicants/status/${onlineUserId}`,
    );
    return response.data;
  } catch (e) {
    console.error('유저 지원 정보 조회 에러', JSON.stringify(e));
    return e.response.data;
  }
};

export async function postFunnelFlowChartChangeFunnel({
  onlineUserId,
  destinationFunnelId,
  roundId,
}) {
  try {
    const response = await instance.post(`dev-funnel-tool/change-funnel`, {
      onlineUserId,
      destinationFunnelId,
      roundId,
    });
    return response.data;
  } catch (e) {
    console.error('Mermaid 퍼널 수정 에러', e);
    return e.response.data;
  }
}

export async function getAllFunnels(
  course: '항해 풀타임' | '항해 플러스' | '',
) {
  try {
    const response = await instance.get(`funnels/all/${course}`);

    return response.data;
  } catch (e) {
    console.error('전 퍼널 조회 에러', e);
    return e.response.data;
  }
}

export async function getApplicantByApplicantId(applicantId: string) {
  const { data } = await instance.get<GetApplicantByApplicantIdResponse>(
    `applicants/${applicantId}`,
  );

  return data;
}
