import { useCallback, useMemo, useState } from 'react';

import { StateMachine, StateMachineHandler } from '@/utils/state-machine';

/**
 * useStateMachine 훅은 상태 머신을 관리하는 커스텀 훅입니다.
 * @description 상태 머신의 현재 상태를 관리하고 상태 전환을 처리합니다.
 *
 * 상태 머신은 상태 전환을 처리하는 상태 머신 정의와 초기 상태, 이전 상태로 돌아가는 이벤트를 전달합니다.
 * 상태 머신 관리를 위한 함수들과 현재 상태를 반환합니다.
 * StateMachine 클래스와 함께 사용하여 상태 머신을 관리합니다.
 *
 * @template S 상태의 타입 (문자열)
 * @template E 이벤트의 타입 (문자열)
 * @param {StateMachine<S, E>} stateMachine 상태 머신 정의
 * @param {S} initialState 초기 상태
 * @param {E} backEvent 이전 상태로 돌아가는 이벤트
 * @returns {{
 *   state: S,
 *   transition: (event: E, onTransition?: (nextState: S) => void) => void,
 *   next: (event: E) => S,
 *   back: () => S,
 *   reset: () => void,
 *   getCurrentState: () => S
 * }} 상태 머신 관리를 위한 함수들과 현재 상태
 */
export function useStateMachine<S extends string, E extends string>(
  stateMachine: StateMachine<S, E>,
  initialState: S,
  backEvent: E,
) {
  const stateMachineHandler = useMemo(
    () => new StateMachineHandler(stateMachine, initialState, backEvent),
    [stateMachine, initialState, backEvent],
  );

  const [state, setState] = useState(stateMachineHandler.getCurrentState());

  const transition = useCallback(
    (event: E, onTransition?: (nextState: S) => void) => {
      const nextState = stateMachineHandler.transition(event);
      setState(nextState);
      onTransition?.(nextState);
    },
    [stateMachineHandler],
  );

  const reset = useCallback(() => {
    stateMachineHandler.reset();
  }, [stateMachineHandler]);

  const next = useCallback(
    (event: E) => {
      const nextState = stateMachineHandler.transition(event);
      setState(nextState);
      return nextState;
    },
    [stateMachineHandler],
  );

  const back = useCallback(() => {
    const nextState = stateMachineHandler.transition(backEvent);
    setState(nextState);
    return nextState;
  }, [stateMachineHandler, backEvent]);

  const getCurrentState = useCallback(() => {
    return stateMachineHandler.getCurrentState();
  }, [stateMachineHandler]);

  return {
    state,
    transition,
    next,
    back,
    reset,
    getCurrentState,
  };
}
