import { useQuery } from 'react-query';

import {
  getAllRoundsByCourseName,
  getPayableRound,
} from '@/services/common/round';
import { Course } from '@/types';

export const roundQueryKey = {
  all: ['round'],
  payableRoundByCourseName: (courseName: string) => [
    ...roundQueryKey.all,
    'payable',
    courseName,
  ],
  allRoundsByCourseName: (courseName: string) => [
    ...roundQueryKey.all,
    'all',
    courseName,
  ],
};

export function useGetPayableRound({ courseName }: { courseName: Course }) {
  return useQuery({
    queryKey: roundQueryKey.payableRoundByCourseName(courseName),
    queryFn: () => getPayableRound(courseName),
    enabled: !!courseName,
  });
}

export function useGetAllRoundsByCourseName({
  courseName,
}: {
  courseName: Course;
}) {
  return useQuery({
    queryKey: roundQueryKey.allRoundsByCourseName(courseName),
    queryFn: () => getAllRoundsByCourseName(courseName),
    enabled: !!courseName,
  });
}
