import { useMutation, useQuery, useQueryClient } from 'react-query';

import { rebootApplicantQueryKeys } from '@/hooks';
import { PatchApplicantFunnelRequestDto } from '@/services/common/applicant';
import {
  getOnGoingApplicantByOnlineUserId,
  patchApplicantFunnel,
} from '@/services/common/applicant/applicant';

export const ongoingApplicantQueryKey = {
  all: ['ongoingApplicant'],
  byOnlineUserId: (onlineUserId: string) => [
    ...ongoingApplicantQueryKey.all,
    onlineUserId,
  ],
};

export function useGetOngoingApplicant({
  onlineUserId,
}: {
  onlineUserId: string;
}) {
  return useQuery({
    queryKey: ongoingApplicantQueryKey.byOnlineUserId(onlineUserId),
    queryFn: () => getOnGoingApplicantByOnlineUserId(onlineUserId),
    enabled: !!onlineUserId,
  });
}

// 성격상 common에 두기는 했으나, invalidate 할 query key를 분간할 수 있는 장치 추가 필요
// 현재는 오직 취업 코스의 '알림신청완료' -> '지원시작' 때문에 사용
export function usePatchApplicantFunnel({
  onlineUserId,
  roundId,
}: {
  onlineUserId: string;
  roundId: string;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: PatchApplicantFunnelRequestDto) =>
      patchApplicantFunnel(body),
    onSuccess: () => {
      queryClient.invalidateQueries(
        rebootApplicantQueryKeys.applicantByOnlineUserIdAndRoundId(
          onlineUserId,
          roundId,
        ),
      );
    },
  });
}
