import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import {
  getRebootApplicant,
  patchPartialRebootApplicant,
  patchRebootApplicant,
  postRebootApplicant,
} from '@/services/reboot/applicant';

export const rebootApplicantQueryKeys = {
  all: ['reboot-applicant'],
  applicantByOnlineUserIdAndRoundId: (
    onlineUserId: string,
    roundId: string,
  ) => [...rebootApplicantQueryKeys.all, onlineUserId, roundId ?? 'LATEST'],
  partial: () => [...rebootApplicantQueryKeys.all, 'partial'],
};

export function useGetRebootApplicant(onlineUserId: string, roundId: string) {
  return useQuery(
    rebootApplicantQueryKeys.applicantByOnlineUserIdAndRoundId(
      onlineUserId,
      roundId,
    ),
    () => getRebootApplicant(onlineUserId, roundId),
    {
      retry: 1,
      enabled: !!onlineUserId,
    },
  );
}

export function useCreateRebootApplicant() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postRebootApplicant,
    onSuccess: (response) => {
      queryClient.invalidateQueries(
        rebootApplicantQueryKeys.applicantByOnlineUserIdAndRoundId(
          response.user.onlineUserId,
          response.round._id,
        ),
      );
    },
    onError: () => {
      queryClient.invalidateQueries(rebootApplicantQueryKeys.all);
      alert(
        '지원자 조회에 문제가 생겼습니다.\n문제가 지속될 경우 고객센터로 문의해주세요.',
      );
    },
  });
}

export function usePatchRebootApplicant() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchRebootApplicant,
    onSuccess: (response) => {
      queryClient.invalidateQueries(
        rebootApplicantQueryKeys.applicantByOnlineUserIdAndRoundId(
          response.user.onlineUserId,
          response.round._id,
        ),
      );
    },
    onError: () => {
      queryClient.invalidateQueries(rebootApplicantQueryKeys.all);
      alert(
        '지원서 제출에 실패했습니다. 다시 시도해주세요. 문제가 지속될 경우 고객센터로 문의해주세요.',
      );
    },
  });
}

export function usePatchPartialRebootApplicant() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: rebootApplicantQueryKeys.partial(),
    mutationFn: patchPartialRebootApplicant,
    onSuccess: (response) => {
      queryClient.invalidateQueries(
        rebootApplicantQueryKeys.applicantByOnlineUserIdAndRoundId(
          response.user.onlineUserId,
          response.round._id,
        ),
      );
    },
    onError: () => {
      queryClient.invalidateQueries(rebootApplicantQueryKeys.all);
      alert(
        '지원서 수정에 실패했습니다. 다시 시도해주세요. 문제가 지속될 경우 고객센터로 문의해주세요.',
      );
    },
  });
}

export function useIsRebootApplicantMutating() {
  const isMutating = useIsMutating({
    mutationKey: rebootApplicantQueryKeys.all,
  });

  return isMutating > 0;
}
