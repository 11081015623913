import { atom, PrimitiveAtom } from 'jotai';

import { type OnlineUserInfo } from '@/providers/OnlineUserInfoProvider';

/**
 * @deprecated onlineUserInfoAtom을 사용해 주세요.
 */
export const onlineUserIdAtom = atom<string>('');

export const onlineUserInfoAtom = atom<OnlineUserInfo | null>(
  null,
) as PrimitiveAtom<OnlineUserInfo | null>;
