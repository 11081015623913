import { ArrowDropDownLine, ArrowDropUpLine } from '@teamsparta/design-system';

const ArrowUpDown = ({ show, color }: { show: boolean; color: string }) => {
  return (
    <>
      {show ? (
        <ArrowDropUpLine size={16} color={color} />
      ) : (
        <ArrowDropDownLine size={16} color={color} />
      )}
    </>
  );
};

export default ArrowUpDown;
