import { PLUS_BACKEND, PLUS_FRONTEND, REBOOT } from '@/constants';
import { COURSE_LANDING_URL } from '@/constants/url';
import { RecruitingStatus } from '@/hooks/react-query/apply';
import { CourseType } from '@/types';

export interface GnbRootMenuGroup {
  type: 'rootGroup';
  title: string;
  children?: Array<GnbMenuGroup | GnbMenuGroupLink>;
  link?: string;
}

export interface GnbMenuGroup {
  type: 'group';
  title: string;
  children: GnbMenuSubGroup[] | GnbMenuItem[];
  isNew?: boolean;
}

export interface GnbMenuGroupLink {
  type: 'groupLink';
  title: string;
  link: string;
  newTab?: boolean;
  isNew?: boolean;
}

export interface GnbMenuSubGroup {
  type: 'subGroup';
  title: string;
  menus: GnbMenuItem[];
}

export interface GnbMenuItem {
  type: 'item';
  title: string;
  link: string;
  courseType?: CourseType | 'AI';
  newTab?: boolean;
  isNew?: boolean;
  mobileHidden?: boolean;
}

type GnbMenu =
  | GnbRootMenuGroup
  | GnbMenuGroup
  | GnbMenuSubGroup
  | GnbMenuItem
  | GnbMenuGroupLink;

export const bootcampMenuGroups: Array<GnbMenuGroup | GnbMenuGroupLink> = [
  {
    type: 'group',
    title: '부트캠프',
    children: [
      {
        type: 'subGroup',
        title: '첫 취업',
        menus: [
          {
            type: 'item',
            link: COURSE_LANDING_URL.HANGHAE_JOB_SUPPORT,
            title: '취업 리부트 코스',
            courseType: REBOOT,
          },
        ],
      },
      {
        type: 'subGroup',
        title: '역량강화/이직',
        menus: [
          {
            type: 'item',
            link: 'https://hhplus-ai.oopy.io/',
            title: '플러스 AI',
            courseType: 'AI',
            newTab: true,
          },
          {
            type: 'item',
            link: COURSE_LANDING_URL.HANGHAE_PLUS_BACKEND,
            title: '플러스 백엔드',
            courseType: PLUS_BACKEND,
          },
          {
            type: 'item',
            link: COURSE_LANDING_URL.HANGHAE_PLUS_FRONTEND,
            title: '플러스 프론트엔드',
            courseType: PLUS_FRONTEND,
          },
        ],
      },
    ],
  },
  {
    type: 'groupLink',
    title: '스터디',
    link: 'https://hanghae99.spartacodingclub.kr/99club',
    newTab: true,
    isNew: true,
    // JSON.parse(process.env.IS_PROD) ?
    // COURSE_LANDING_URL의 99CLUB_CODING_TEST를 수정할 수 있지만, 그 변수를 사용하는 곳이 많고,
    //     // gnb에서 클릭 시에만 99클럽 허브(?)로 이동하기 때문에 하드코딩으로 수정
    //     '/99club'
    //   : `https://testhanghae99.spartacodingclub.kr/99club`,
  },
  {
    type: 'groupLink',
    title: '후기&스토리',
    link: '/blog',
    newTab: true,
  },
  {
    type: 'groupLink',
    title: '웨비나',
    link: '/webinar',
    newTab: false,
  },
];

export const GnbMenuList: GnbMenu[] = [
  {
    type: 'rootGroup',
    title: '부트캠프',
    children: bootcampMenuGroups,
  },
  {
    type: 'item',
    title: '스터디',
    link: 'https://hanghae99.spartacodingclub.kr/99club',
    newTab: true,
    // JSON.parse(process.env.IS_PROD) ?
    // COURSE_LANDING_URL의 99CLUB_CODING_TEST를 수정할 수 있지만, 그 변수를 사용하는 곳이 많고,
    //     // gnb에서 클릭 시에만 99클럽 허브(?)로 이동하기 때문에 하드코딩으로 수정
    //     '/99club'
    //   : `https://testhanghae99.spartacodingclub.kr/99club`,
    isNew: true,
    mobileHidden: true,
  },
  { type: 'item', title: '웨비나', link: '/webinar' },
  { type: 'item', title: '후기&스토리', link: '/blog', newTab: true },
];

export const MobileGnbMenuList: GnbMenu[] = [
  { type: 'item', title: '나의 수강', link: '/users/my-page?tab=나의 수강' },
  { type: 'item', title: '결제 내역', link: '/users/my-page?tab=결제 내역' },
  { type: 'item', title: '계정 정보', link: '/users/my-page?tab=계정 정보' },
];

export function isGroup(item: GnbMenu): item is GnbMenuGroup {
  return item.type === 'group';
}

export function isSubGroup(item: GnbMenu): item is GnbMenuSubGroup {
  return item.type === 'subGroup';
}

export function isGroupLink(item: GnbMenu): item is GnbMenuGroupLink {
  return item.type === 'groupLink';
}

export function isItem(item: GnbMenu): item is GnbMenuItem {
  return item.type === 'item';
}

export const BADGE_TEXT_MAP: Record<RecruitingStatus, string> = {
  LOADING: '',
  RECRUITING: '모집 중',
  ALIM: '알림신청',
};
