import { instance } from '@/queries/api/axios';
import { getApplicantByOnlineUserId } from '@/services/common/applicant';
import { instanceV2 } from '@/services/instance';
import {
  CreateRebootApplicantRequestDto,
  GetRebootApplicantResponseDto,
  PatchNotifiedRebootApplicantRequestDto,
  PatchPartialRebootApplicantRequestDto,
  PatchRebootApplicationRequestDto,
  PatchRebootApplicationResponseDto,
  PostRebootApplicationResponseDto,
} from '@/services/reboot/applicant/applicant.dto';

export async function getRebootApplicant(
  onlineUserId: string,
  roundId?: string,
): Promise<GetRebootApplicantResponseDto> {
  return await getApplicantByOnlineUserId<GetRebootApplicantResponseDto>(
    onlineUserId,
    '취업 리부트 코스',
    roundId,
  );
}

export async function postRebootApplicant(
  body: CreateRebootApplicantRequestDto,
): Promise<PostRebootApplicationResponseDto> {
  const { data } = await instance.post<PostRebootApplicationResponseDto>(
    'v3/applicants/reboot',
    body,
  );

  return data;
}

export async function patchRebootApplicant(
  body:
    | PatchRebootApplicationRequestDto
    | PatchNotifiedRebootApplicantRequestDto,
): Promise<PatchRebootApplicationResponseDto> {
  const { data } = await instance.patch<PatchRebootApplicationResponseDto>(
    `v3/applicants/reboot`,
    body,
  );

  return data;
}

export async function patchPartialRebootApplicant(
  body: PatchPartialRebootApplicantRequestDto,
) {
  const data = await instanceV2.patch<PatchRebootApplicationResponseDto>(
    `v3/applicants/reboot-application`,
    body,
  );

  return data;
}
