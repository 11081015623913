import { useAtom } from 'jotai';
import { useQuery } from 'react-query';

import { userApplicationAtom } from '@/states/application';
import { onlineUserIdAtom } from '@/states/user';

import { getUserApplication } from './api/application/application';
import { UserApplicationDto } from './api/application/application.dto';

export const useUserApplication = () => {
  const [onlineUserId] = useAtom(onlineUserIdAtom);
  const [, setUserApplication] = useAtom(userApplicationAtom);
  const response = useQuery<UserApplicationDto | undefined, Error>(
    ['userApplicationInfo'],
    () => getUserApplication(onlineUserId),
    {
      enabled: onlineUserId.length > 0,
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: (data) => {
        if (data.status === 404 || data.status === 500) {
          alert('지원하는 데에 문제가 생겼습니다. 매니저에게 문의해주세요!'); //todo: useUserApplication은 Layout에서 호출되는 만큼 좀더 범용적인 문구로 바꾸기
          window.location.replace(process.env.NEXT_PUBLIC_PAGE_URL);
          return;
        }
        setUserApplication(data);
      },
    },
  );
  return response;
};
