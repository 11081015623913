import { useEffect, useRef, useState } from "react";

export type ScrollDirection = "up" | "down";

export const useScrollDirection = (): ScrollDirection => {
  const [d, setD] = useState<ScrollDirection>("up");
  const lastScrollTop = useRef(0);
  useEffect(() => {
    const scrollHandler = () => {
      const st = window.scrollY || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > lastScrollTop.current) {
        setD("down");
      } else {
        setD("up");
      }
      lastScrollTop.current = st <= 0 ? 0 : st;
    };
    window.addEventListener("scroll", scrollHandler, false);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);
  return d;
};
