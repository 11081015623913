import { useEffect, useLayoutEffect } from 'react';

import { isServer } from '@/utils/helpers/enviroment';

/**
 * 서버사이드 렌더링에서는 useEffect를 사용하고, 클라이언트에서는 useLayoutEffect를 사용하는 훅
 * @description 서버사이드에선 useLayoutEffect를 사용하지 못하기에 useEffect를 사용
 */
export const useIsomorphicLayoutEffect = isServer()
  ? useEffect
  : useLayoutEffect;
