import {
  type GnbMenuItem,
  type GnbMenuSubGroup,
  isItem,
  isSubGroup,
} from '@/constants/gnb';

/**
 * 현재 Gnb 서브 그룹에서 활성화된 메뉴가 있는지 확인
 * @description Gnb 서브 그룹 중 현재 활성화 된 아이템이 포함되어 있는 서브 그룹이 있는지 확인합니다.
 * @param subGroups GnbMenuSubGroup[]
 * @param compareFn (item: GnbMenuItem) => boolean
 */

export function isActivatedSubGroup(
  subGroups: GnbMenuSubGroup[] | GnbMenuItem[],
  compareFn: (item: GnbMenuItem) => boolean,
) {
  return subGroups.some((subGroup) => {
    return isSubGroup(subGroup)
      ? subGroup.menus.some(compareFn)
      : isItem(subGroup) && compareFn(subGroup);
  });
}
