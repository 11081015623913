import { adjustTimeByHours } from '@/utils/date/date';

/**
 * 현재 시간의 UTC offset을 시간 단위로 반환합니다.
 * @description UTC+0 기준으로 현재 시간이 얼마나 늦거나 빠른지를 반환합니다.
 * 9시간 늦으면 9, 9시간 빠르면 -9를 반환합니다.
 */
export function getCurrentUTCOffsetHours() {
  return new Date().getTimezoneOffset() / 60;
}

/**
 * @deprecated - adjustDateByPurpose 함수를 사용해 주세요.
 * @description date에 UTC offset을 더하거나 뺀 새로운 Date 객체를 반환합니다.
 * 보통 DB에 저장된 값은 한국시간이지만 UTC+0 인 경우입니다.
 * 해당 값을 Date 객체로 변환한다면 타임존에 따라 UTC offset이 적용된 값이 나오기에 어떤 타임존이라도 다시금 UTC Offset을 제거하여 한국 시간을 얻기 위해 조정을 해야합니다.
 * @param date Date 객체 or Date ISO String
 * @returns 조정된 Date 객체
 */
export function adjustTimeByUTCOffsetHours(date: Date | string) {
  const offsetHours = getCurrentUTCOffsetHours();
  return adjustTimeByHours(date, offsetHours);
}

/**
 * @deprecated - adjustDateByPurpose 함수를 사용해 주세요.
 * @description - KST 기준으로 저장된 date 값을 UTC+0으로 조정하는 함수입니다.
 * 보통 DB에 저장된 값은 한국시간이지만 UTC+0 인 경우입니다.
 * 해당 값을 Date 객체로 변환한다면 타임존에 따라 UTC offset이 적용된 값이 나오기에 한국시간이지만 부정확한 UTC를 조정하기 위해 사용합니다.
 * @param date Date 객체 or Date ISO String
 * @returns 조정된 Date 객체
 */
export function adjustTimeToUTCFromKST(date: Date | string) {
  return adjustTimeByHours(date, -9);
}

/**
 * 입력된 날짜를 UTC로 조정하거나, 현재 시스템의 UTC 오프셋을 적용하여 조정합니다.
 * @param date Date 객체 or Date ISO String
 * @param purpose 'calc' 또는 'display' - 계산용이면 UTC로 조정, 표시용이면 시스템의 UTC 오프셋을 적용하여 한국 시간으로 조정
 * @returns 조정된 Date 객체
 */
export function adjustDateByPurpose(
  date: Date | string,
  purpose: 'calc' | 'display',
): Date {
  if (purpose === 'calc') {
    return adjustTimeToUTCFromKST(date);
  } else if (purpose === 'display') {
    return adjustTimeByUTCOffsetHours(date);
  } else {
    throw new Error(
      '유효하지 않은 목적입니다. calc 또는 display를 입력해주세요.',
    );
  }
}
