import { getCookie } from 'cookies-next';
import { ReactNode, useEffect } from 'react';
import { z } from 'zod';

import { useSetOnlineUser } from '@/hooks/user/useOnlineUser';
import { parseQueryStringToObject } from '@/utils/parse';

const onlineUserInfoSchema = z.object({
  _id: z.string(),
  name: z.string(),
  email: z.string(),
  phone: z.string(),
  country_code: z.string(),
  birthday: z.string(),
  birthyear: z.string(),
});

export type OnlineUserInfo = z.infer<typeof onlineUserInfoSchema>;

export function OnlineUserInfoProvider({ children }: { children: ReactNode }) {
  const setOnlineUser = useSetOnlineUser();

  useEffect(() => {
    const userInfo = getCookie('userinfo');
    if (!userInfo) return;

    const parsedUserInfo = parseQueryStringToObject(userInfo);
    const onlineUserInfo = onlineUserInfoSchema.safeParse(parsedUserInfo);

    if (onlineUserInfo.success) {
      setOnlineUser(onlineUserInfo.data);
    }
  }, [setOnlineUser]);

  return <>{children}</>;
}
