import { useQuery } from 'react-query';

import { getCourseDetail } from '@/constants';
import { useGetAllRoundsByCourseName } from '@/hooks/react-query/common/round';
import { getEnrollableRound, Round } from '@/services/common/round';
import { CourseType } from '@/types';

import { useGetNotifiableRound } from '../notification';

export type RecruitingStatus = 'LOADING' | 'RECRUITING' | 'ALIM';

export const recruitingRoundQueryKeys = {
  all: ['recruitingRound'],
  recruitingRoundByCourse: (course: CourseType) => ['recruitingRound', course],
};

export function useRecruitingStatusByCourse({
  course,
}: {
  course: CourseType;
}): {
  status: RecruitingStatus;
  recruitingRound?: Round | null;
  notifiableRound?: Round | null;
  endRound?: Round | null;
} {
  const courseName = getCourseDetail(course)?.koreanName;
  const { data: recruitingRound, isLoading } = useQuery(
    recruitingRoundQueryKeys.recruitingRoundByCourse(course),
    () => getEnrollableRound(courseName),
    {
      enabled: !!courseName,
    },
  );
  const { data: notifiableRound, isLoading: isNotifiableRoundLoading } =
    useGetNotifiableRound({ courseName });
  const { data: allRounds, isLoading: isAllRoundsLoading } =
    useGetAllRoundsByCourseName({ courseName });

  let status: RecruitingStatus = 'LOADING';
  let endRound: Round | null = null;

  if (isLoading || isNotifiableRoundLoading || isAllRoundsLoading) {
    return { status };
  }

  if (recruitingRound) {
    status = 'RECRUITING';
  }

  if (recruitingRound === null && notifiableRound !== null) {
    status = 'ALIM';
  }

  /**
   * @description Notification 컴포넌트의 endRound에 사용될 round
   */
  if (allRounds?.length >= 2 && notifiableRound !== null) {
    const notifiableRoundIndex = allRounds.findIndex(
      (round) => round._id === notifiableRound._id,
    );
    endRound = allRounds?.[notifiableRoundIndex + 1] ?? null;
  }

  return { status, recruitingRound, notifiableRound, endRound };
}
