import Script from 'next/script';
import React from 'react';

// 부정클릭 방지 스크립트
export function SmartLog() {
  const hpt_info = `var hpt_info = {'_account':'UHPT-18545', '_server': 'a23'};`;

  return (
    <>
      <Script
        id='double_click_script'
        dangerouslySetInnerHTML={{ __html: hpt_info }}
      />
      <Script src='//cdn.smlog.co.kr/core/smart.js' />
      <noscript>
        <img
          src='//a23.smlog.co.kr/smart_bda.php?_account=18545'
          style={{ display: 'none', width: 0, height: 0, border: 0 }}
        />
      </noscript>
    </>
  );
}
