import {
  useIsMutating,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from 'react-query';

import { getCourseDetail } from '@/constants';
import {
  getPlusApplicant,
  patchPartialPlusApplicant,
  patchPlusApplicant,
  patchPlusApplicantRecommender,
  postPlusApplicant,
} from '@/services/plus/applicant';
import {
  GetPlusApplicantResponseDto,
  PatchPlusApplicantRecommenderRequestDto,
  PatchPlusApplicantRequestDto,
  PostPlusApplicantRequestDto,
} from '@/services/plus/applicant/applicant.dto';
import { Course, PlusCourseRoute } from '@/types';
import { removeLocalStorageItem } from '@/utils/local-storage';
import { sendGTM } from '@/utils/sdk/google-tag-manager';
import { sendLog } from '@/utils/send-log';

export const plusApplicantQueryKeys = {
  all: ['plus-applicant'],
  applicantByOnlineUserIdAndRoundId: (
    onlineUserId: string,
    course: Omit<Course, '취업 리부트 코스'>,
    roundId?: string,
  ) => [
    ...plusApplicantQueryKeys.all,
    onlineUserId,
    course,
    roundId ?? 'LATEST',
  ],
};

export function useGetPlusApplicant({
  onlineUserId,
  course,
  roundId,
}: {
  onlineUserId: string;
  course: PlusCourseRoute;
  roundId?: string;
}) {
  return useQuery(
    plusApplicantQueryKeys.applicantByOnlineUserIdAndRoundId(
      onlineUserId,
      getCourseDetail(course).koreanName,
      roundId,
    ),
    () => getPlusApplicant(onlineUserId, course, roundId),
  );
}

export function useCreatePlusApplicant(course: PlusCourseRoute) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: PostPlusApplicantRequestDto) =>
      postPlusApplicant({ body, course }),
    onSuccess: (response) => {
      queryClient.invalidateQueries(
        plusApplicantQueryKeys.applicantByOnlineUserIdAndRoundId(
          response.user.onlineUserId,
          response.course.name,
          response.round._id,
        ),
      );
    },
    onError: () => {
      queryClient.invalidateQueries(plusApplicantQueryKeys.all);
      alert(
        '지원서 제출에 실패했습니다. 다시 시도해주세요. 문제가 지속될 경우 고객센터로 문의해주세요.',
      );
    },
  });
}

export function usePatchPlusApplicant({
  onlineUserId,
  course,
  localStorageKey,
}: {
  onlineUserId: string;
  course: PlusCourseRoute;
  localStorageKey?: string;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: PatchPlusApplicantRequestDto) =>
      patchPlusApplicant({ body, course }),
    onSuccess: async (updatedApplicant) => {
      await queryClient.invalidateQueries(
        plusApplicantQueryKeys.applicantByOnlineUserIdAndRoundId(
          onlineUserId,
          getCourseDetail(course).koreanName,
          updatedApplicant.round._id,
        ),
      );
      const applicant = queryClient.getQueryData<GetPlusApplicantResponseDto>(
        plusApplicantQueryKeys.applicantByOnlineUserIdAndRoundId(
          onlineUserId,
          getCourseDetail(course).koreanName,
          updatedApplicant.round._id,
        ),
      );
      sendGTM({
        event: 'hh_application1_completed',
        online_user_id: onlineUserId,
        email: applicant.user.email,
        phone: `+82${applicant.user.phone.substring(1)}`,
        content_category: 'hanghae',
        content_type: getCourseDetail(course).type,
        content_name: '',
      });
      sendLog('hhplus_application1_completed', {
        ...applicant,
        course_type: getCourseDetail(course).koreanPosition,
      });
      removeLocalStorageItem(
        localStorageKey ?? `hanghae-plus-${course}-applicant-${onlineUserId}`,
      );
    },
  });
}

export function usePatchPartialPlusApplicant() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: plusApplicantQueryKeys.all,
    mutationFn: patchPartialPlusApplicant,
    onSuccess: async (applicant) => {
      await queryClient.invalidateQueries(
        plusApplicantQueryKeys.applicantByOnlineUserIdAndRoundId(
          applicant.user.onlineUserId,
          applicant.course.name,
          applicant.round._id,
        ),
      );
    },
  });
}

export function usePatchPlusApplicantRecommender(
  mutateOptions: UseMutationOptions<
    void,
    unknown,
    PatchPlusApplicantRecommenderRequestDto
  >,
) {
  return useMutation({
    mutationFn: patchPlusApplicantRecommender,
    ...mutateOptions,
  });
}

export function useIsMutatingPlusApplicant() {
  const mutationCount = useIsMutating({
    mutationKey: plusApplicantQueryKeys.all,
  });

  return mutationCount > 0;
}
