import { adjustTimeByUTCOffsetHours, isTodayBetweenDates } from '@/utils/date';

export const WHITE_LIST_FUNNEL_CONTEXT_FOR_PAYABLE = ['최종합격', '결제완료'];

export function isWhiteListFunnelContextForPayable(funnelContext: string) {
  return WHITE_LIST_FUNNEL_CONTEXT_FOR_PAYABLE.includes(funnelContext);
}

/**
 * @description - enrollStartDate ~ enrollEndDate 사이에는 누구든 퍼널 진입 가능.
 *              - enrollEndDate ~ paymentEndDate 사이면서, funnel context가 white list이면 퍼널 진입 가능.
 */
export function isAccessible({
  round,
  funnelContext,
}: {
  round: {
    enrollStartDate: Date | string;
    enrollEndDate: Date | string;
    paymentEndDate: Date | string;
  };
  funnelContext: string;
}) {
  if (!round) return false;

  const { enrollStartDate, enrollEndDate, paymentEndDate } = round;
  const canApplyAnyone = isTodayBetweenDates(
    adjustTimeByUTCOffsetHours(enrollStartDate),
    adjustTimeByUTCOffsetHours(enrollEndDate),
  );

  if (canApplyAnyone) return true;

  return (
    isTodayBetweenDates(
      adjustTimeByUTCOffsetHours(enrollEndDate),
      adjustTimeByUTCOffsetHours(paymentEndDate),
    ) && isWhiteListFunnelContextForPayable(funnelContext)
  );
}
