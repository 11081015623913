import { useEffect } from 'react';

import { assert } from '@/utils/assert';

import { usePreserveCallback } from './usePreserveCallback';

interface UseIntervalProps {
  /**
   * 타이머 시간이 되면 실행되는 콜백 함수
   */
  onTick: () => void;
  /**
   * 타이머 시간 (밀리초)
   */
  delay: number;
  /**
   * 타이머를 비활성화할지 여부
   */
  disabled?: boolean;
}

/**
 * 주기적으로 실행되는 타이머 훅
 *
 * @param props UseIntervalProps
 * @throws {Error} delay가 0 이하일 경우 에러를 던집니다.
 *
 * @example
 * ```tsx
 * const [count, setCount] = useState(0);
 * useInterval({ onTick: () => setCount(count + 1), delay: 1000 });
 * ```
 */
export function useInterval({ onTick, delay, disabled }: UseIntervalProps) {
  assert(delay > 0, 'delay는 0보다 커야 합니다.');

  const onTickCallback = usePreserveCallback(onTick);

  useEffect(() => {
    if (disabled) return;

    const interval = setInterval(onTickCallback, delay);
    return () => clearInterval(interval);
  }, [onTickCallback, delay, disabled]);
}
