import { useEffect } from "react";

export function useOutsideClicked(ref, set) {
  // const [isClicked, setClickedStatus] = useState(false);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      // setClickedStatus(false);
      set(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  // return isClicked;
}
