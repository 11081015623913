import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { DefaultValues, FieldValues, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/utils/local-storage';

export default function useLocalStorageReactHookForm<
  FormSchema extends FieldValues,
>(
  key: string,
  resolverSchema: z.Schema<Record<string, any>>,
  defaultValues?: DefaultValues<FormSchema>,
) {
  const methods = useForm<FormSchema>({
    resolver: zodResolver(resolverSchema),
    defaultValues,
  });

  useEffect(() => {
    const storedFormData = getLocalStorageItem(key);
    if (storedFormData) {
      methods.reset(storedFormData);
    }
  }, [key, methods]);

  useEffect(() => {
    function handleFormValuesChange(data: any) {
      setLocalStorageItem<FormSchema>(key, data);
    }

    const subscription = methods.watch(handleFormValuesChange);
    return () => subscription.unsubscribe();
  }, [key, methods]);

  return methods;
}
