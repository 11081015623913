/**
 * 상태 전환을 정의하는 인터페이스입니다.
 * @template S 상태의 타입
 * @template E 이벤트의 타입
 */
interface StateTransition<S, E> {
  nextState: S;
  onEvent: E;
  condition?: (currentState: S, event: E, stack: S[], events: E[]) => boolean;
}

/**
 * 상태 머신의 구조를 정의하는 인터페이스입니다.
 * @template S 상태의 타입 (문자열)
 * @template E 이벤트의 타입 (문자열)
 */
export interface StateMachine<S extends string, E extends string> {
  [state: string]: StateTransition<S, E>[];
}

/**
 * 상태 머신을 구축하는 빌더 클래스입니다.
 * @template S 상태의 타입 (문자열)
 * @template E 이벤트의 타입 (문자열)
 */
export class StateMachineBuilder<S extends string, E extends string> {
  private stateMachine: StateMachine<S, E> = {};

  /**
   * 상태 전환을 추가합니다.
   * @param state 현재 상태
   * @param event 발생 이벤트
   * @param nextState 다음 상태
   * @param condition 전환 조건 (선택적)
   * @returns 빌더 인스턴스
   */
  addTransition(
    state: S,
    event: E,
    nextState: S,
    condition?: (currentState: S, event: E, stack: S[], events: E[]) => boolean,
  ): StateMachineBuilder<S, E> {
    if (!this.stateMachine[state]) {
      this.stateMachine[state] = [];
    }
    this.stateMachine[state].push({ nextState, onEvent: event, condition });
    return this;
  }

  /**
   * 구축된 상태 머신을 반환합니다.
   * @returns 완성된 상태 머신
   */
  build(): StateMachine<S, E> {
    return this.stateMachine;
  }
}

/**
 * 상태 머신을 처리하는 핸들러 클래스입니다.
 * @template S 상태의 타입 (문자열)
 * @template E 이벤트의 타입 (문자열)
 */
export class StateMachineHandler<S extends string, E extends string> {
  private stateMachine: StateMachine<S, E>;
  private currentState: S;
  private stateStack: S[];
  private initialState: S;
  private backEvent: E;
  private events: E[];

  /**
   * StateMachineHandler의 생성자입니다.
   * @param stateMachine 상태 머신 정의
   * @param initialState 초기 상태
   * @param backEvent 이전 상태로 돌아가는 이벤트
   */
  constructor(stateMachine: StateMachine<S, E>, initialState: S, backEvent: E) {
    this.stateMachine = stateMachine;
    this.currentState = initialState;
    this.initialState = initialState;
    this.stateStack = [];
    this.backEvent = backEvent;
    this.events = [];
  }

  /**
   * 주어진 이벤트에 따라 상태를 전환합니다.
   * @param event 발생 이벤트
   * @returns 새로운 현재 상태
   */
  transition(event: E): S {
    this.events.push(event);

    if (event === this.backEvent) {
      if (this.stateStack.length > 0) {
        this.currentState = this.stateStack.pop()!;
      }
    } else {
      const transitions = this.stateMachine[this.currentState];
      if (!transitions) {
        return this.currentState;
      }

      const validTransition = transitions.find(
        (transition) =>
          transition.onEvent === event &&
          (!transition.condition ||
            transition.condition(
              this.currentState,
              event,
              this.stateStack,
              this.events,
            )),
      );

      if (validTransition) {
        this.stateStack.push(this.currentState);
        this.currentState = validTransition.nextState;
      }
    }

    return this.currentState;
  }

  /**
   * 현재 상태를 반환합니다.
   * @returns 현재 상태
   */
  getCurrentState(): S {
    return this.currentState;
  }

  /**
   * 지정된 상태로 직접 이동합니다.
   * @param state 이동할 상태
   */
  moveToState(state: S): void {
    this.stateStack.push(this.currentState);
    this.currentState = state;
    console.info(`직접 ${this.currentState}로 이동했습니다`);
  }

  /**
   * 상태 머신을 초기 상태로 재설정합니다.
   */
  reset(): void {
    this.currentState = this.initialState;
    this.stateStack = [];
    this.events = [];
  }
}
