import { useQuery } from 'react-query';

import { getCourseDetail } from '@/constants';
import { getHanghaePlusMentoringReservationPages } from '@/services/plus/whattime';
import { Course } from '@/types';

export function useGetHanghaePlusMentoringReservationPages({
  courseKoreanName,
}: {
  courseKoreanName: Course;
}) {
  const koreanPosition = getCourseDetail(courseKoreanName)?.koreanPosition;

  return useQuery({
    queryKey: ['hanghaePlusMentoringReservationPages'],
    queryFn: () => getHanghaePlusMentoringReservationPages(),
    select: (data) =>
      data.collection.filter(
        (reservationPage) =>
          reservationPage.team.name.toLowerCase() ===
          `항해플러스_${koreanPosition}`.toLowerCase(),
      ),
    onError: (error) => {
      alert('예약 페이지 로딩에 실패했습니다. 고객 센터에 문의바랍니다.');
      console.error(error);
    },
    useErrorBoundary: true,
  });
}
