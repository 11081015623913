import axios from 'axios';

const whattimeAxiosInstance = axios.create({
  baseURL: 'https://api.whattime.co.kr/v1',
  headers: {
    Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGgud2hhdHRpbWUuY28ua3IiLCJpYXQiOjE2NzIxMzQ3MDgsImp0aSI6Ijc1UEU1Z2paOExWRDVYVkp3dzN4IiwidXNlcl9jb2RlIjoieXliMW01OGVXcCJ9.igRN9v_fcEFoiD0NZH1FKskK9gzHQOGgz9KyB2GKqh8`,
  },
});

type ReservationPages = {
  collection: {
    name: string;
    reservation_url: string;
    team: {
      name: string;
    };
  }[];
};

export async function getHanghaePlusMentoringReservationPages() {
  const { data } = await whattimeAxiosInstance.get<ReservationPages>(
    '/calendars',
    {
      params: {
        organization: 'https://api.whattime.co.kr/v1/organizations/7X3sJHKFo5',
        active: true,
        order: 'desc',
        per: 20,
      },
    },
  );

  return data;
}
