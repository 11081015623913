import {
  ArrowRightLine,
  Divider,
  Flex,
  neutralDay,
  Text,
} from '@teamsparta/design-system';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRef, useState } from 'react';

import { logout } from '@/businessLogics/login/login';
import OutsideClickedWrapper from '@/components/atoms/Common/OutsideClickedWrapper';
import { MY_PAGES } from '@/constants';
import { useOnlineUser } from '@/hooks';

import * as S from './UserGnbButton.style';

export default function UserGnbButton() {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const timerId = useRef<NodeJS.Timer | null>(null);
  const userInfo = useOnlineUser();

  function handleHover(trigger: 'enter' | 'leave') {
    return function () {
      if (trigger === 'enter') {
        if (timerId.current) clearTimeout(timerId.current);
        setTooltipVisible(true);
      } else {
        timerId.current = setTimeout(() => {
          setTooltipVisible(false);
        }, 300);
      }
    };
  }

  return (
    <S.ButtonWrapper
      onMouseEnter={handleHover('enter')}
      onMouseLeave={handleHover('leave')}
      onClick={handleHover('enter')}
    >
      <Image
        src='/images/icons/person_icon.png'
        width={24}
        height={24}
        alt='signin-icon'
      />
      <OutsideClickedWrapper setState={setTooltipVisible}>
        <S.TooltipBox visible={tooltipVisible}>
          <Text
            as='span'
            font='wBody1'
            color={neutralDay.white}
            style={{
              padding: '10px 0',
            }}
          >
            안녕하세요, {userInfo?.name} 님!
          </Text>
          <S.LinkButtonList>
            {MY_PAGES.map(({ name, href }) => (
              <Link key={name} href={href} passHref>
                <Flex.Row
                  align='center'
                  justify='between'
                  style={{
                    padding: '10px 0',
                  }}
                >
                  <Text as='span' font='wBody4' color={neutralDay.white}>
                    {name}
                  </Text>
                  <ArrowRightLine size={16} color={`${neutralDay.gray50}`} />
                </Flex.Row>
              </Link>
            ))}
          </S.LinkButtonList>
          <Divider color={neutralDay.gray80} />
          <S.GnbLogoutButton onClick={logout}>로그아웃</S.GnbLogoutButton>
        </S.TooltipBox>
      </OutsideClickedWrapper>
    </S.ButtonWrapper>
  );
}
