import { getCourseDetail } from '@/constants';
import { instance } from '@/queries/api/axios';
import { getApplicantByOnlineUserId } from '@/services/common/applicant';
import { instanceV2 } from '@/services/instance';
import {
  PatchPartialPlusApplicantRequestDto,
  PatchPartialPlusApplicationResponseDto,
} from '@/services/plus/applicant/applicant.dto';
import { PlusCourseRoute } from '@/types';

import {
  GetPlusApplicantResponseDto,
  PatchPlusApplicantRecommenderRequestDto,
  PatchPlusApplicantRequestDto,
  PatchPlusApplicationResponseDto,
  PostPlusApplicantRequestDto,
} from './applicant.dto';

export async function getPlusApplicant(
  onlineUserId: string,
  course: PlusCourseRoute,
  roundId?: string,
) {
  const { koreanName } = getCourseDetail(course);

  return await getApplicantByOnlineUserId<GetPlusApplicantResponseDto>(
    onlineUserId,
    koreanName,
    roundId,
  );
}

export async function postPlusApplicant({
  body,
  course,
}: {
  body: PostPlusApplicantRequestDto;
  course: PlusCourseRoute;
}): Promise<GetPlusApplicantResponseDto> {
  const { englishPosition, koreanName } = getCourseDetail(course);

  const { data } = await instance.post<GetPlusApplicantResponseDto>(
    `v3/applicants/plus-${englishPosition}`,
    {
      courseName: koreanName,
      ...body,
    },
  );

  return data;
}

export async function patchPlusApplicant({
  body,
  course,
}: {
  body: PatchPlusApplicantRequestDto;
  course: PlusCourseRoute;
}) {
  const englishCourseName = getCourseDetail(course).englishPosition;

  return await instanceV2.patch<PatchPlusApplicationResponseDto>(
    `v3/applicants/plus-${englishCourseName}`,
    body,
  );
}

/**
 * @description 지원자 정보를 부분적으로 업데이트합니다.
 */
export async function patchPartialPlusApplicant(
  body: PatchPartialPlusApplicantRequestDto,
) {
  const { data } = await instance.patch<PatchPartialPlusApplicationResponseDto>(
    `v3/applicants/plus-application`,
    body,
  );

  return data;
}

export async function patchPlusApplicantRecommender({
  applicantId,
  recommendationCode,
}: PatchPlusApplicantRecommenderRequestDto) {
  await instanceV2.patch('v3/applicants/recommender', {
    applicantId,
    recommendationCode,
  });
}
