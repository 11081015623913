import {
  GnbMenuGroup,
  GnbMenuGroupLink,
  GnbMenuItem,
  isGroupLink,
  isItem,
  isSubGroup,
} from '@/constants/gnb';

/**
 * 현재 그룹에서 활성화된 메뉴가 있는지 확인
 * @description Gnb 그룹 중 현재 활성화 된 아이템이 포함되어 있는 그룹이 있는지 확인합니다.
 * @param groups GnbMenuGroup[]
 * @param compareFn (item: GnbMenuItem) => boolean
 */
export function getIsActivatedGroup(
  groups: Array<GnbMenuGroup | GnbMenuGroupLink>,
  compareFn: (item: GnbMenuItem | GnbMenuGroupLink) => boolean,
) {
  return groups.some((menu) => {
    if (isGroupLink(menu)) {
      return compareFn(menu);
    }
    return menu.children.some((subMenu) => {
      if (isSubGroup(subMenu)) {
        return subMenu.menus.some(compareFn);
      }
      if (isItem(subMenu)) {
        return compareFn(subMenu);
      }
    });
  });
}
