import { FallbackRender } from '@sentry/react/types/errorboundary';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import Gnb from '@/components/molecules/GNB';
import { useOnlineUser } from '@/hooks';
import { Result } from '@/pages/error/components';
import { errorDataMap } from '@/pages/error/data';

import * as S from './style';

type ErrorPageTemplateProps = {
  statusCode?: 404 | 500;
  onlineUserId?: string;
} & Partial<Parameters<FallbackRender>[0]>;

/**
 * 에러 페이지 템플릿
 * @description 페이지 최상단에서 사용하는 템플릿
 * @param statusCode 에러 상태 코드 (404, 500)
 * @param resetError 에러 초기화 함수
 * @param onlineUserId 온라인 사용자 아이디
 * @constructor
 */
export default function ErrorPageTemplate({
  statusCode = 404,
  resetError,
  onlineUserId,
}: ErrorPageTemplateProps) {
  const router = useRouter();
  const userInfo = useOnlineUser();
  const errorData = errorDataMap[statusCode];

  function handleClickButton() {
    if (errorData.destination === 'previous') {
      router.back();
    } else {
      router.push(errorData.destination);
    }
  }

  useEffect(() => {
    if (!resetError) {
      return;
    }

    router.events.on('routeChangeComplete', resetError);
    return () => {
      router.events.off('routeChangeComplete', resetError);
    };
  }, [resetError, router.events]);

  return (
    <S.Container>
      <Gnb fixed scrollY={0} ssrOnlineUserId={onlineUserId ?? userInfo?._id} />
      <S.ResultWrapper>
        <Result
          figure={errorData.figure}
          title={<Result.Title>{errorData.title}</Result.Title>}
          description={
            <Result.Description>{errorData.description}</Result.Description>
          }
          bottomAddon={
            <Result.Button onClick={handleClickButton}>
              {errorData.buttonContent}
            </Result.Button>
          }
        />
      </S.ResultWrapper>
    </S.Container>
  );
}
