import { LoginProcess } from '@teamsparta/auth-frontend';
import * as CPL from '@teamsparta/cross-platform-logger';
import { useAtom, useAtomValue } from 'jotai';
import { ReactNode, useEffect } from 'react';

import { useUserApplication } from '@/queries/application';
import { isLoginModalShowAtom, nextPageAfterLoginAtom } from '@/states/login';
import { onlineUserIdAtom } from '@/states/user';
import { getUserInfoFromClientCookie } from '@/utils/cookie';
import { SmartLog } from '@/utils/sdk';

export default function Layout({ children }: { children: ReactNode }) {
  const [, setOnlineUserId] = useAtom(onlineUserIdAtom);
  useUserApplication();

  /**
   * 로그인 패키지 관련 코드
   */
  const initialTextProps = {
    subTitle: '개발자 커리어 개척 캠프',
    title: '항해99',
    desc: '집요한 끈기와 몰입으로\n결국 무엇이든 해내는 개발자로 거듭나세요',
  };
  const [isLoginModalShow, setIsLoginModalShow] = useAtom(isLoginModalShowAtom);
  const nextPageAfterLogin = useAtomValue(nextPageAfterLoginAtom);

  function setOnlineUserIdFromCookie() {
    const { _id } = getUserInfoFromClientCookie();
    setOnlineUserId(_id);
  }

  useEffect(() => {
    setOnlineUserIdFromCookie();
  }, []);

  useEffect(() => {
    function handleResize() {
      let vw = document.documentElement.clientWidth / 100;
      document.documentElement.style.setProperty('--vw', `${vw}px`);
    }

    handleResize();

    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <SmartLog />
      <LoginProcess
        isShow={isLoginModalShow}
        setIsShow={setIsLoginModalShow}
        nextPage={nextPageAfterLogin}
        initialTextProps={initialTextProps}
        CPLogger={CPL}
      />
      <>{children}</>
    </>
  );
}
